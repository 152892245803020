import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SiteTitle from './components/SiteTitle';
import Header from './components/Header';
import ReadingList from './components/ReadingList';
import Projects from './components/Projects';
import Notes from './components/Notes';
import Footer from './components/Footer';
import CurrentlyListening from './components/CurrentlyListening';
import Books from './components/Books';
import ResearchPapers from './components/ResearchPapers';
import Blogs from './components/Blogs';
import Tweets from './components/Tweets';

function App() {
  return (
    <Router>
      <div className="App">
        <SiteTitle />
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <CurrentlyListening />
              <main>
                <ReadingList />
                <Projects />
                <Notes />
              </main>
            </>
          } />
          <Route path="/books" element={<Books />} />
          <Route path="/research-papers" element={<ResearchPapers />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/tweets" element={<Tweets />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;