import React from 'react';
import { Link } from 'react-router-dom';

function ReadingList() {
  return (
    <section>
      <div className="reading-list-container">
      <h2>Reading List</h2>
      <ul>
        <li><Link to="/books">Books</Link></li>
        <li><Link to="/research-papers">Research Papers</Link></li>
        <li><Link to="/blogs">Blogs</Link></li>
        <li><Link to="/tweets">Tweets (Xeets)</Link></li>
      </ul>
      </div>
    </section>
  );
}

export default ReadingList;