import React from 'react';

const Blogs = () => {
    return (
        <div className='page'>
            <h2>Blogs</h2>
            <ul>
                <li>Blog Post 1</li>
                <li>Blog Post 2</li>
                <li>Blog Post 3</li>
                <li>Blog Post 4</li>
                <li>Blog Post 5</li>
            </ul>
        </div>
    );
};

export default Blogs;
