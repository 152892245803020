import React from 'react';
import { Link } from 'react-router-dom';

const ResearchPapers = () => {
    return (
        <div className='page'>
            <h2>Research Papers</h2>
            <ul>
                <li>Paper 1</li>
                <li>Paper 2</li>
                <li>Paper 3</li>
                <li>Paper 4</li>
                <li>Paper 5</li>
            </ul>
        </div>
    );
};

export default ResearchPapers;
