import React from 'react';

const Tweets = () => {
    return (
        <div className='page'>
            <h2>Tweets (Xeets)</h2>
            <ul>
                <li>Tweet 1</li>
                <li>Tweet 2</li>
                <li>Tweet 3</li>
                <li>Tweet 4</li>
                <li>Tweet 5</li>
            </ul>
        </div>
    );
};

export default Tweets;
