import React from 'react';

const Books = () => {
    return (
        <div className='books-page'>
            <h2>Books</h2>
            <ul>
                <li>History</li>
                <li>Fiction</li>
                <li>Self-help</li>
                <li>Science</li>
                <li>Biography</li>
                <li>Business</li>
                <li>Travel</li>
                <li>Psychology</li>
                <li>Health</li>
                <li>Philosophy</li>
            </ul>
        </div>
    );
};

export default Books;