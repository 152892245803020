import React from 'react';
import { Link } from 'react-router-dom';

function SiteTitle() {
  return (
    <header className="site-title">
      <h1><Link to="/">Smit T</Link></h1>
    </header>
  );
}

export default SiteTitle;
