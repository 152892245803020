import React from 'react';

function Notes() {
  return (
    <section>
      <h2>Notes</h2>
      <ul>
        <li><a href="#">Note 1 <span>(100 words)</span></a></li>
        <li><a href="#">Note 2 <span>(200 words)</span></a></li>
        <li><a href="#">Note 3 <span>(150 words)</span></a></li>
      </ul>
    </section>
  );
}

export default Notes;