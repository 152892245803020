import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_KEY = '79c1efc739ff4c7984bf02e81facb087';
const USERNAME = 'Thakkarsmit';

function CurrentlyListening() {
  const [recentTrack, setRecentTrack] = useState(null);
  const [isCurrentlyPlaying, setIsCurrentlyPlaying] = useState(false);

  useEffect(() => {
    const fetchRecentTrack = async () => {
      try {
        const response = await axios.get(`https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=${USERNAME}&api_key=${API_KEY}&format=json&limit=1`);
        const track = response.data.recenttracks.track[0];
        if (track) {
          setRecentTrack(track);
          setIsCurrentlyPlaying(track['@attr'] && track['@attr'].nowplaying === 'true');
        }
      } catch (error) {
        console.error('Error fetching recent track:', error);
      }
    };

    fetchRecentTrack();
    const interval = setInterval(fetchRecentTrack, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  if (!recentTrack) return null;

  return (
    <div className="currently-listening">
      <h3>{isCurrentlyPlaying ? "Now Playing" : "Last Played"}</h3>
      <p>{recentTrack.name} by {recentTrack.artist['#text']}</p>
    </div>
  );
}

export default CurrentlyListening;
